import { getImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";

interface Props {
	title: string;
	src: string; // URL to the video file
	poster: IGatsbyImageData;
	className?: string;
}

const Video: React.FC<Props> = ({ title, src, poster, className }) => {
	const posterSrc = getImage(poster)?.images?.fallback?.src;
	return (
		<video
			className={`aspect-video w-full rounded-lg shadow-lg ${className || ""}`}
			src={src}
			controls
			poster={posterSrc}
			title={title}
		>
			{/* Add track element to satisfy accessibility requirements */}
			<track kind="captions" src="" label="Deutsch" />
		</video>
	);
};

export default Video;
