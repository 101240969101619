// Packages
import React from "react";
import { graphql } from "gatsby";

// Types
import type { SiteMetadata, Location } from "~types/Gatsby";
import { GatsbyImage, getImage, type IGatsbyImageData } from "gatsby-plugin-image";

// Components
import Layout from "~components/layouts/Layout";
import Button from "~components/common/Button";
import SubTitle from "~components/typography/SubTitle";
import H1 from "~components/typography/H2";
import P from "~components/typography/P";
import Hero from "~components/sections/Hero";
import Ul from "~components/typography/Ul";
import H3 from "~components/typography/H3";
import Container from "~components/common/Container";
import Grid from "~components/common/Grid";
import Video from "~components/common/Video";

const AMAZON_BOOK_URL =
	"https://www.amazon.de/entfalten-Eishockeyspieler-volles-Potential-Profi-Eishockey/dp/B0F286W27G/ref=tmm_pap_swatch_0";
interface DataProps {
	site: {
		siteMetadata: SiteMetadata;
	};
	imageHeader: IGatsbyImageData;
	profileImage: IGatsbyImageData;
	imageTestmonial: IGatsbyImageData;
	imageVideoCover: IGatsbyImageData;
	imageBook: IGatsbyImageData;
}

export default function Page({ data, location }: { data: DataProps; location: Location }) {
	const imageBook = getImage(data.imageBook);
	return (
		<Layout
			location={location}
			title="Sport Coaching"
			desc=""
			// imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			// imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			// imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			// imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
		>
			<Hero image={data.imageHeader} alt="Sport Coaching mit Daniel Thiel">
				<SubTitle className="mb-2">Sport Coaching für Athleten auf Höchstleistungsniveau</SubTitle>
				<H1 display className="mb-4">
					Steigere deine Leistung mit systemischem Mental- & Performance-Coaching{" "}
				</H1>
				<P>Speziell entwickelt für Athleten, die ihre Grenzen überschreiten wollen: </P>
				<Ul
					items={[
						<>
							<b>Individuelles 10-Wochen-Programm:</b>
							<br />
							Erhalte persönliches Coaching, das auf deine Bedürfnisse als Athlet zugeschnitten ist, mit wöchentlichen
							Sitzungen von 90-120 Minuten, die darauf ausgerichtet sind, deine sportliche Leistung zu maximieren.
						</>,
						<>
							<b>Mentale Stärke und Leistungsdruck-Management:</b>
							<br />
							Lerne, mentale Stärke zu entwickeln und effektiv mit dem Druck umzugehen, der auf höchstem Leistungsniveau
							entsteht. Unser Coaching hilft dir, auch unter extremen Bedingungen Höchstleistungen zu erbringen.
						</>,
						<>
							<b>Hypnose-Techniken:</b>
							<br />
							Nutze Hypnose-Techniken für mentale Klarheit und Performance-Maximierung. Diese Ansätze sind darauf
							ausgerichtet, dein mentales Wohlbefinden zu steigern und dir zu helfen, deine kognitiven und emotionalen
							Ressourcen optimal zu nutzen.
						</>,
						<>
							<b>Vorträge und individuelle Betreuung:</b>
							<br />
							Zusätzlich zu personalisiertem Coaching bieten wir Vorträge und Workshops speziell für Profisportler, um
							tiefgreifende Einblicke und fortgeschrittene Techniken zur Leistungssteigerung zu teilen.
						</>,
						<>
							<b>Expertise und Erfahrung:</b>
							<br />
							Profitiere von meiner umfangreichen Erfahrung und Expertise, die ich bei der Mehr Geschäft Coaching GmbH
							und der Greator GmbH gesammelt habe, mit einem starken Fokus auf Umsatzsteigerung, Wachstum und mentale
							Entwicklung.
						</>,
						<>
							<b>Fachliche Autorität:</b>
							<br />
							Als Autor des Buches „So entfalten Eishockeyspieler ihr volles Potenzial“ bringe ich spezialisiertes
							Wissen und tiefe Einblicke in die Welt des Mentaltrainings und der Performance-Optimierung für Athleten.{" "}
						</>,
					]}
					className="mb-8"
					pretty
				></Ul>
				<H3 className="mb-4">Preisgestaltung</H3>
				<P className="mb-4">
					Jeder Athlet und jedes Team ist einzigartig, und deshalb bieten wir flexible Coaching-Optionen an, die auf
					deine spezifischen Anforderungen zugeschnitten sind. Preis auf Anfrage.{" "}
				</P>
				<P className="mb-10">
					Bist du bereit, deine sportliche Leistung auf das nächste Level zu heben? Fülle das Kontaktformular aus oder
					erreiche uns direkt, um deine Coaching-Session zu planen.
				</P>
				<Button to="/coaching/sport/anfragen/" text="Termin vereinbaren" />
			</Hero>
			<Container>
				<Video
					title="Sport Coaching"
					src="https://cms.daniel-thiel.com/wp-content/uploads/2025/03/so-entfalten-eishockeyspieler-ihr-volles-potential.mp4"
					poster={data.imageVideoCover}
					className="mx-auto max-w-2xl"
				/>
			</Container>

			<Container>
				<Grid cols={2}>
					<div>
						{imageBook ? (
							<a href={AMAZON_BOOK_URL} target="_blank" rel="noreferrer">
								<GatsbyImage image={imageBook} alt="Das Mentale Coaching-Buch" className="mx-auto max-w-2xl" />
							</a>
						) : null}
					</div>
					<div>
						<div className="prose">
							<h2>Das Erfolgsgeheimnis der Top-Athleten? - Mentales 1 : 1 Coaching!</h2>
							<p>
								Die besten Spieler der Welt unterscheiden sich nicht durch ihr Talent, sondern durch ihre mentale
								St&auml;rke. Sie haben gelernt, unter Druck Bestleistungen abzurufen, R&uuml;ckschl&auml;ge zu nutzen
								und in den entscheidenden Momenten voll fokussiert zu bleiben. - Doch wie genau gelingt das?
							</p>
							<p>Dieses Buch gibt dir mentale Werkzeuge, um dein Spiel auf das n&auml;chste Level zu bringen!</p>
							<p>
								<strong>Stell dir vor, du kannst:</strong>
							</p>
							<ul>
								<li>
									✅ <strong>Mentale Blockaden durchbrechen</strong> und jederzeit dein volles Leistungsverm&ouml;gen
									abrufen
								</li>
								<li>
									✅<strong>Mit Druck und R&uuml;ckschl&auml;gen souver&auml;n umgehen</strong>, und mit
									unersch&uuml;tterlichem Selbstvertrauen auftreten
								</li>
								<li>
									✅<strong>Deine Morgenroutine nutzen</strong>, um mit maximaler mentaler Klarheit in den Tag zu
									starten
								</li>
								<li>
									✅<strong>Dein Unterbewusstsein auf Erfolg programmieren</strong> und dich mental auf Sieg trimmen
									<br />✅<strong>In entscheidenden Momenten H&ouml;chstleistung abrufen</strong> und fokussierter als
									je zuvor spielen
									<br />
									<br /> In <em>&bdquo;</em>
									<em>
										<strong>So entfalten Eishockeyspieler ihr volles Potenzial</strong>
									</em>
									<em>&ldquo;</em> zeigt Daniel Thiel, wie du mit <strong>Selbst-Hypnose</strong>,{" "}
									<strong>Sportpsychologie</strong> sowie bew&auml;hrten{" "}
									<strong>Strategien aus dem Business-Coaching</strong> und der{" "}
									<strong>F&uuml;hrungskr&auml;fteentwicklung</strong> deine mentale Performance auf ein neues Level
									bringst.
								</li>
							</ul>
							<p>
								Der Autor hat dieses Wissen speziell f&uuml;r Eishockeyspieler heruntergebrochen und daraus ein
								praxisnahes Trainingsbuch entwickelt &ndash; f&uuml;r alle, die nicht nur spielen, sondern dominieren
								und Gewinnen wollen.
							</p>
							<p>
								<strong>Das Erfolgsgeheimnis der Top-Athleten? - Mentales Coaching!</strong>
							</p>
							<p>
								In diesem Buch findest du praxiserprobte Methoden, die bereits von Profisportlern, Trainern und
								Leistungstr&auml;gern erfolgreich genutzt werden &ndash; kompakt, verst&auml;ndlich und direkt umsetzbar
								f&uuml;r dein eigenes Spiel.
							</p>
							<p>
								<strong>Dieses Buch ist dein Gamechanger. Dein Durchbruch beginnt hier.</strong>
							</p>
						</div>
						<Button href={AMAZON_BOOK_URL} text="Jetzt kaufen" target="_blank" className="mt-4" />
					</div>
				</Grid>
			</Container>
		</Layout>
	);
}

export const query = graphql`
	{
		site {
			siteMetadata {
				siteUrl
			}
		}
		seoImage: file(relativePath: { eq: "placeholder-meta.png" }) {
			facebook: childImageSharp {
				gatsbyImageData(height: 630, width: 1200, formats: JPG, quality: 100)
			}
			twitter: childImageSharp {
				gatsbyImageData(aspectRatio: 1, formats: JPG, quality: 100)
			}
		}

		imageHeader: file(relativePath: { eq: "pages/coaching/sport/header.jpeg" }) {
			childImageSharp {
				gatsbyImageData
			}
		}

		imageVideoCover: file(relativePath: { eq: "pages/coaching/sport/video-cover.png" }) {
			childImageSharp {
				gatsbyImageData
			}
		}
		imageBook: file(relativePath: { eq: "pages/coaching/sport/book-cover.jpeg" }) {
			childImageSharp {
				gatsbyImageData
			}
		}
	}
`;
